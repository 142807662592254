
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';

@Component({})
export default class Home extends Vue {
  @State opId!: number;
  @Mutation setOpId!: (opId: number) => void;

  @Watch('opId')
  onOpIdChanged() {
    this.navigateToDevices();
  }

  mounted() {
    this.navigateToDevices();
  }

  navigateToDevices(): void {
    const opId = this.$route.params.opId || this.opId;
    if (!opId) return;

    this.setOpId(Number(opId));

    this.$router.push({
      name: 'devices-view',
      params: {
        opId: `${opId}`,
      },
    });
  }
}
